import { useAppDispatch, useAppSelector } from "../../../store";
import {
  chooseCompany,
  fetchComanies,
  updateDefaultCompany,
} from "../../../store/user/userAction";
import ChooseAccountForm from "../../../components/desktop/forms/choseAccountForm/ChoseAccountForm";
import { selectCompany } from "../../../store/user/userSlice";
import { useEffect, useState } from "react";

const ChoseAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchComanies());
  }, [dispatch]);

  const companies = useAppSelector((state) => state.user.companies) || [];

  const handleSubmit = (
    defaultCompanyId: number | null,
    selectedCompanyId: number | null
  ) => {
    setIsLoading(true);
    dispatch(chooseCompany({ CompanyId: selectedCompanyId || 0 })).then(() => {
      dispatch(selectCompany({ selectedCompanyId }));
      dispatch(updateDefaultCompany({ defaultCompanyId }));
      setIsLoading(false);
    });
  };

  return (
    <ChooseAccountForm
      loading={isLoading}
      onSubmit={handleSubmit}
      companies={companies}
    />
  );
};

export default ChoseAccount;
