import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BarChartTitleContainer,
  GroupBarChartContainer,
  GroupDetailChartWrapper,
  Header,
  NavLink,
  SkeletonWrapper,
  Title,
  TitleWrapper,
  TreeChartContainer,
  Wrapper,
} from "./RevenueInDetail.style";

import BigCardSkeleton from "../../../../components/shared/dashboard/skeletons/bigCardSkeleton/BigCardSkeleton";

import Filters from "../../../../components/shared/dashboard/filters/Filters";
import CardGroupsSkeleton from "../../../../components/shared/dashboard/skeletons/cardGroupsSkeleton/CardGroupsSkeleton";
import DashboardChartCard from "../../../../components/shared/card/Card";

import { useAppDispatch, useAppSelector } from "../../../../store";
import { getRevenueInDetail } from "../../../../store/dashboard/dashboardAction";
import {
  getCompanyBranch,
  getCompanyBusinessLine,
} from "../../../../store/company/companyAction";
import {
  CardTypes,
  ChartMode,
  ChartType,
  PeriodType,
  RangeType,
} from "../../../../enums/enum";

import TreeChart from "../../../../components/desktop/charts/revenueExpenses/treeChart/TreeChart";

import TableChart from "../../../../components/desktop/charts/revenueExpenses/tableChart/TableChart";

import { formatNumber } from "../../../../helpers/dashboardHelper";
import { Link, useParams } from "react-router-dom";
import Icon from "../../../../components/shared/icon/Icon";
import BarChartLabels from "../../../../components/shared/dashboard/barChartLabels/BarChartLabels";
import GroupBarChart from "../../../../components/desktop/charts/revenueExpenses/groupBar/GroupBarChart";
import EmptyDashboard from "../../../../components/shared/emptyDashboard/EmptyDashboard";
import {
  initialRevenueExpensesFilterData,
  updateRevenueFilter,
} from "../../../../store/dashboard/dashboardSlice";
import { sumRevenueExpenses } from "../../../../helpers/profitLossHelper";

const RevenueInDetail = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  let { groupId, accountId } = useParams();

  const [isShowClearAll, setIsShowClearAll] = useState(false);

  const [cardViewMode, setCardViewMode] = useState<ChartMode>(ChartMode.Tree);

  const [chartType] = useState<ChartType>(ChartType.GROUP);

  const revenueFilter = useAppSelector(
    (state) => state.dashboard.revenueFilter
  );

  const revenue = useAppSelector(
    (state) => state.dashboard.revenueDetailChartData
  );

  const loading = useAppSelector((state) => state.dashboard.revenueLoading);

  const hasNoData = useMemo(() => {
    return revenue.data.every((item) => item.sumAmount === 0);
  }, [revenue.data]);

  const totalSum = useMemo(() => {
    return formatNumber(sumRevenueExpenses(revenue.data));
  }, [revenue.data]);

  useEffect(() => {
    const promises = [
      dispatch(getCompanyBusinessLine()),
      dispatch(getCompanyBranch()),
    ];
    Promise.all(promises).finally(() => {});
  }, [dispatch]);

  useEffect(() => {
    if (!accountId) return;
    dispatch(
      getRevenueInDetail({
        periodType: revenueFilter.period,
        fromDate: revenueFilter.dateRange.range[0],
        toDate: revenueFilter.dateRange.range[1],
        chartType: ChartType.GROUP,
        accountId,
      })
    );
  }, [dispatch, chartType, accountId, revenueFilter]);

  const updateFilter = ({
    periodType,
    dateRange,
    dateType,
  }: {
    periodType?: PeriodType;
    dateRange?: Array<string>;
    dateType?: RangeType;
  }) => {
    let data = { ...revenueFilter };

    if (periodType) {
      data.period = periodType;
    }

    if (dateRange) {
      data.dateRange = { ...data.dateRange, range: dateRange };
    }
    if (dateType) {
      data.dateRange = { ...data.dateRange, type: dateType };
    }

    dispatch(updateRevenueFilter(data));
  };

  const clearFilter = () => {
    dispatch(updateRevenueFilter(initialRevenueExpensesFilterData));
  };

  return (
    <Wrapper>
      <Header>
        <TitleWrapper>
          <Title>{t("RevenueInDetail.Title")}</Title>
          <NavLink>
            <Link to={`/app/Revenue/${groupId}`}>
              <Icon iconName="leftArrow" height={12} width={7} />
              <span> {t("RevenueInDetail.SubTitle")}</span>
            </Link>
          </NavLink>
        </TitleWrapper>
        <Filters
          isShowClearAll={isShowClearAll}
          selectedDateRange={revenueFilter.dateRange}
          handleDateRange={(dateData) => {
            setIsShowClearAll(true);
            updateFilter({
              dateRange: dateData.range,
              dateType: dateData.type,
            });
          }}
          handleClearAll={() => {
            setIsShowClearAll(false);
            clearFilter();
          }}
        />
      </Header>
      {loading.groupDetail === "pending" ? (
        <SkeletonWrapper>
          <BigCardSkeleton />
          <CardGroupsSkeleton />
        </SkeletonWrapper>
      ) : (
        <>
          {hasNoData ? (
            <EmptyDashboard />
          ) : (
            <>
              <DashboardChartCard
                selectedPeriodType={revenueFilter.period}
                selectedChartType={chartType}
                selectedViewType={cardViewMode}
                type={CardTypes.LARGE}
                title={t("RevenueInDetail.DashboardChartCardTitle")}
                value={totalSum}
                handlePeriodType={(periodType) => {
                  updateFilter({ periodType });
                }}
                handleViewMode={setCardViewMode}
              >
                {cardViewMode === ChartMode.Stacked && (
                  <GroupBarChartContainer>
                    <BarChartLabels data={revenue.data} />
                    <GroupBarChart
                      data={revenue.data}
                      periodType={revenueFilter.period}
                      stackId={"a"}
                    />
                  </GroupBarChartContainer>
                )}
                {cardViewMode === ChartMode.Tree && (
                  <TreeChartContainer>
                    <TreeChart data={revenue.data} />
                  </TreeChartContainer>
                )}
              </DashboardChartCard>
              <GroupDetailChartWrapper>
                <BarChartTitleContainer>
                  <Title>{t("RevenueInDetail.BarChartTitle")}</Title>
                </BarChartTitleContainer>
                <TableChart
                  periodType={revenueFilter.period}
                  data={revenue.data}
                />
              </GroupDetailChartWrapper>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default RevenueInDetail;
