import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  CashFlowType,
  ChartType,
  PeriodType,
  RangeType,
} from "../../enums/enum";
import { LoaderType } from "../type";

import { processAccountData } from "../../helpers/dashboardHelper";
import {
  FinancialGroupsResponse,
  MarketData,
  ReportalReportItem,
  FinancialRecord,
  FinancialInDetailResponse,
  RevenueFilter,
  ExpensesFilter,
  NetIncomeResponse,
  CashFlowGroupsResponse,
} from "./dashboard.types";

import {
  getCashFlowByGroup,
  getCashFlowCategoryTypeId,
  getCashFlowCategoryTypeWithDetail,
  getExpensesGroupById,
  getExpensesGroups,
  getExpensesInDetail,
  getMarketAllCompanyInfo,
  getMarketCompanyInfo,
  getNetIncomeInfo,
  getReportalReport,
  getRevenueGroupById,
  getRevenueGroups,
  getRevenueInDetail,
} from "./dashboardAction";
import moment from "moment";
import { getRange365Days } from "../../helpers/dateHelper";

export const initialRevenueExpensesFilterData = {
  dateRange: {
    type: RangeType.Past365Days,
    range: getRange365Days(),
  },
  period: PeriodType.Month,
  chartType: ChartType.GROUP,
  groups: [],
  accountIds: [],
  branches: [],
  businessLines: [],
};

export type DashboardState = {
  hasError: boolean;
  loading: LoaderType;
  revenueFilter: RevenueFilter;
  revenueLoading: {
    groups: LoaderType;
    groupById: LoaderType;
    groupDetail: LoaderType;
  };
  revenueGroupChartData: FinancialGroupsResponse;
  revenueGroupByIdChartData: FinancialGroupsResponse;
  revenueDetailChartData: FinancialGroupsResponse;
  revenueGroupsOption: Array<{ id: string; name: string }>;
  revenueGroupByIdOption: Array<{ id: string; name: string }>;
  expensesFilter: ExpensesFilter;
  expensesLoading: {
    groups: LoaderType;
    groupById: LoaderType;
    groupDetail: LoaderType;
  };
  expensesGroupChartData: FinancialGroupsResponse;
  expensesGroupByIdChartData: FinancialGroupsResponse;
  expensesDetailChartData: FinancialGroupsResponse;
  expensesGroupsOption: Array<{ id: string; name: string }>;
  expensesGroupByIdOption: Array<{ id: string; name: string }>;
  netIncomeLoading: LoaderType;
  netIncomeData: NetIncomeResponse;
  cashInLoading: LoaderType;
  cashInData: CashFlowGroupsResponse;
  cashInByTypeLoading: LoaderType;
  cashInByTypeData: CashFlowGroupsResponse;
  cashInByTypeCategoryLoading: LoaderType;
  cashInByTypeCategory: CashFlowGroupsResponse;
  cashOutLoading: LoaderType;
  cashOutData: CashFlowGroupsResponse;
  cashOutByTypeLoading: LoaderType;
  cashOutByTypeData: CashFlowGroupsResponse;
  cashOutByTypeCategoryLoading: LoaderType;
  cashOutByTypeCategory: CashFlowGroupsResponse;
  netCashLoading: LoaderType;
  netCashData: CashFlowGroupsResponse;
  netCashByTypeLoading: LoaderType;
  netCashByTypeData: CashFlowGroupsResponse;
  netCashByTypeCategoryLoading: LoaderType;
  netCashByTypeCategory: CashFlowGroupsResponse;
  reportalData: {
    financeStanding: Array<ReportalReportItem>;
    workOutcome: Array<ReportalReportItem>;
  };
  market: MarketData;
  marketWorkOutcomeCompanyInfo: Array<{
    idCode: number;
    orgNameInReport: string;
  }>;
};

const initialState = (): DashboardState => ({
  hasError: false,
  loading: "idle",
  revenueFilter: initialRevenueExpensesFilterData,
  revenueLoading: {
    groups: "idle",
    groupById: "idle",
    groupDetail: "idle",
  },
  revenueGroupsOption: [],
  revenueGroupByIdOption: [],
  revenueGroupChartData: { data: [], lastUpdateTime: "" },
  revenueGroupByIdChartData: { data: [], lastUpdateTime: "" },
  revenueDetailChartData: { data: [], lastUpdateTime: "" },
  expensesFilter: initialRevenueExpensesFilterData,
  expensesLoading: {
    groups: "idle",
    groupById: "idle",
    groupDetail: "idle",
  },
  expensesGroupsOption: [],
  expensesGroupByIdOption: [],
  expensesGroupChartData: { data: [], lastUpdateTime: "" },
  expensesGroupByIdChartData: { data: [], lastUpdateTime: "" },
  expensesDetailChartData: { data: [], lastUpdateTime: "" },
  netIncomeLoading: "idle",
  netIncomeData: { data: [], lastUpdateTime: "" },
  cashInLoading: "idle",
  cashInData: { data: [], lastUpdateTime: "" },
  cashInByTypeLoading: "idle",
  cashInByTypeData: { data: [], lastUpdateTime: "" },
  cashInByTypeCategoryLoading: "idle",
  cashInByTypeCategory: { data: [], lastUpdateTime: "" },
  cashOutLoading: "idle",
  cashOutData: { data: [], lastUpdateTime: "" },
  cashOutByTypeLoading: "idle",
  cashOutByTypeData: { data: [], lastUpdateTime: "" },
  cashOutByTypeCategoryLoading: "idle",
  cashOutByTypeCategory: { data: [], lastUpdateTime: "" },
  netCashLoading: "idle",
  netCashData: { data: [], lastUpdateTime: "" },
  netCashByTypeLoading: "idle",
  netCashByTypeData: { data: [], lastUpdateTime: "" },
  netCashByTypeCategoryLoading: "idle",
  netCashByTypeCategory: { data: [], lastUpdateTime: "" },

  // todo need remove
  reportalData: { financeStanding: [], workOutcome: [] },
  market: {
    identificationCode: null,
    name: null,
    companyCategoryId: null,
    companyFormId: null,
    data: undefined,
  },

  marketWorkOutcomeCompanyInfo: [],
});

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState: JSON.parse(JSON.stringify(initialState())),
  reducers: {
    resetDashboardStore: () => initialState(),
    updateRevenueFilter: (
      state,
      {
        payload,
      }: {
        payload: RevenueFilter;
      }
    ) => {
      state.revenueFilter = payload;
    },
    updateExpensesFilter: (
      state,
      {
        payload,
      }: {
        payload: ExpensesFilter;
      }
    ) => {
      state.expensesFilter = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<DashboardState>) => {
    builder
      .addCase(getRevenueGroups.pending, (state) => {
        state.revenueLoading.groups = "pending";
      })
      .addCase(
        getRevenueGroups.fulfilled,
        (state, { payload }: { payload: FinancialGroupsResponse }) => {
          state.revenueLoading.groups = "succeeded";
          state.revenueGroupsOption = payload.filters || [];
          state.revenueGroupChartData = payload;
        }
      )
      .addCase(getRevenueGroups.rejected, (state) => {
        state.revenueLoading.groups = "failed";
        state.revenueGroupsOption = [];
        state.revenueGroupChartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      });
    // getRevenueGroupById
    builder
      .addCase(getRevenueGroupById.pending, (state) => {
        state.revenueLoading.groupById = "pending";
      })
      .addCase(
        getRevenueGroupById.fulfilled,
        (state, { payload }: { payload: FinancialGroupsResponse }) => {
          state.revenueLoading.groupById = "succeeded";
          state.revenueGroupByIdOption = payload.filters || [];
          state.revenueGroupByIdChartData = payload;
        }
      )
      .addCase(getRevenueGroupById.rejected, (state) => {
        state.revenueLoading.groupById = "failed";
        state.revenueGroupByIdOption = [];
        state.revenueGroupByIdChartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      });
    // getRevenueInDetail
    builder
      .addCase(getRevenueInDetail.pending, (state) => {
        state.revenueLoading.groupDetail = "pending";
      })
      .addCase(
        getRevenueInDetail.fulfilled,
        (state, { payload }: { payload: FinancialInDetailResponse }) => {
          state.revenueLoading.groupDetail = "succeeded";
          const ordering = processAccountData(payload.data);

          const data: Array<FinancialRecord> = payload.data.map((item) => ({
            id: item.accountId,
            name: item.accountName,
            ordering: ordering[item.accountId],
            period: item.period,
            parentOrdering: ordering[item.accountId],
            sumAmount: item.total,
          }));

          state.revenueDetailChartData = {
            data: data,
            lastUpdateTime: payload.lastUpdateTime,
          };
        }
      )
      .addCase(getRevenueInDetail.rejected, (state) => {
        state.revenueLoading.groupDetail = "failed";
        state.revenueDetailChartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })

      // getExpensesGroups
      .addCase(getExpensesGroups.pending, (state) => {
        state.expensesLoading.groups = "pending";
      })
      .addCase(
        getExpensesGroups.fulfilled,
        (state, { payload }: { payload: FinancialGroupsResponse }) => {
          state.expensesLoading.groups = "succeeded";
          state.expensesGroupsOption = payload.filters || [];
          state.expensesGroupChartData = payload;
        }
      )
      .addCase(getExpensesGroups.rejected, (state) => {
        state.expensesLoading.groups = "failed";
        state.expensesGroupsOption = [];
        state.expensesGroupChartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })
      // getExpensesGroupById
      .addCase(getExpensesGroupById.pending, (state) => {
        state.expensesLoading.groupById = "pending";
      })
      .addCase(
        getExpensesGroupById.fulfilled,
        (state, { payload }: { payload: FinancialGroupsResponse }) => {
          state.expensesLoading.groupById = "succeeded";
          state.expensesGroupByIdOption = payload.filters || [];
          state.expensesGroupByIdChartData = payload;
        }
      )
      .addCase(getExpensesGroupById.rejected, (state) => {
        state.expensesLoading.groupById = "failed";
        state.expensesGroupByIdOption = [];
        state.expensesGroupByIdChartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })

      // getExpensesInDetail
      .addCase(getExpensesInDetail.pending, (state) => {
        state.expensesLoading.groupDetail = "pending";
      })
      .addCase(
        getExpensesInDetail.fulfilled,
        (state, { payload }: { payload: FinancialInDetailResponse }) => {
          state.expensesLoading.groupDetail = "succeeded";
          const ordering = processAccountData(payload.data);

          const data: Array<FinancialRecord> = payload.data.map((item) => ({
            id: item.accountId,
            name: item.accountName,
            ordering: ordering[item.accountId],
            parentOrdering: ordering[item.accountId],
            period: item.period,
            sumAmount: item.total,
          }));

          state.expensesDetailChartData = {
            data: data,
            lastUpdateTime: payload.lastUpdateTime,
          };
        }
      )
      .addCase(getExpensesInDetail.rejected, (state) => {
        state.expensesLoading.groupDetail = "failed";
        state.expensesDetailChartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })
      // getNetIncomeInfo
      .addCase(getNetIncomeInfo.pending, (state) => {
        state.netIncomeLoading = "pending";
      })
      .addCase(
        getNetIncomeInfo.fulfilled,
        (state, { payload }: { payload: NetIncomeResponse }) => {
          state.netIncomeLoading = "succeeded";
          state.netIncomeData = payload;
        }
      )
      .addCase(getNetIncomeInfo.rejected, (state) => {
        state.netIncomeLoading = "failed";
        state.netIncomeData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })
      // getCashFlowByGroup
      .addCase(getCashFlowByGroup.pending, (state) => {
        state.cashInLoading = "pending";
        state.cashOutLoading = "pending";
        state.netCashLoading = "pending";
      })
      .addCase(
        getCashFlowByGroup.fulfilled,
        (state, { payload }: { payload: CashFlowGroupsResponse }) => {
          state.cashInLoading = "succeeded";
          state.cashOutLoading = "succeeded";
          state.netCashLoading = "succeeded";
          if (payload.type === CashFlowType.CashIn) {
            state.cashInData = payload;
          }
          if (payload.type === CashFlowType.CashOut) {
            state.cashOutData = payload;
          }
          if (payload.type === CashFlowType.NetCash) {
            state.netCashData = payload;
          }
        }
      )
      .addCase(getCashFlowByGroup.rejected, (state) => {
        state.cashInLoading = "failed";
        state.cashOutLoading = "failed";
        state.netCashLoading = "failed";
        state.cashInData = { data: [], lastUpdateTime: "" };
        state.cashOutData = { data: [], lastUpdateTime: "" };
        state.netCashData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })

      // getCashFlowCategoryTypeId
      .addCase(getCashFlowCategoryTypeId.pending, (state) => {
        state.cashInByTypeLoading = "pending";
        state.cashOutByTypeLoading = "pending";
        state.netCashByTypeLoading = "pending";
      })
      .addCase(
        getCashFlowCategoryTypeId.fulfilled,
        (state, { payload }: { payload: CashFlowGroupsResponse }) => {
          state.cashInByTypeLoading = "succeeded";
          state.cashOutByTypeLoading = "succeeded";
          state.netCashByTypeLoading = "succeeded";
          if (payload.type === CashFlowType.CashIn) {
            state.cashInByTypeData = payload;
          }
          if (payload.type === CashFlowType.CashOut) {
            state.cashOutByTypeData = payload;
          }
          if (payload.type === CashFlowType.NetCash) {
            state.netCashByTypeData = payload;
          }
        }
      )
      .addCase(getCashFlowCategoryTypeId.rejected, (state) => {
        state.cashInByTypeLoading = "failed";
        state.cashOutByTypeLoading = "failed";
        state.netCashByTypeLoading = "failed";
        state.cashInData = { data: [], lastUpdateTime: "" };
        state.cashOutData = { data: [], lastUpdateTime: "" };
        state.netCashData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })

      // getCashFlowCategoryTypeId
      .addCase(getCashFlowCategoryTypeWithDetail.pending, (state) => {
        state.cashInByTypeCategoryLoading = "pending";
        state.cashOutByTypeCategoryLoading = "pending";
        state.netCashByTypeCategoryLoading = "pending";
      })
      .addCase(
        getCashFlowCategoryTypeWithDetail.fulfilled,
        (state, { payload }: { payload: CashFlowGroupsResponse }) => {
          state.cashInByTypeCategoryLoading = "succeeded";
          state.cashOutByTypeCategoryLoading = "succeeded";
          state.netCashByTypeCategoryLoading = "succeeded";
          if (payload.type === CashFlowType.CashIn) {
            state.cashInByTypeCategory = payload;
          }
          if (payload.type === CashFlowType.CashOut) {
            state.cashOutByTypeCategory = payload;
          }
          if (payload.type === CashFlowType.NetCash) {
            state.netCashByTypeCategory = payload;
          }
        }
      )
      .addCase(getCashFlowCategoryTypeWithDetail.rejected, (state) => {
        state.cashInByTypeCategoryLoading = "failed";
        state.cashOutByTypeCategoryLoading = "failed";
        state.netCashByTypeCategoryLoading = "failed";
        state.cashInByTypeCategory = { data: [], lastUpdateTime: "" };
        state.cashOutByTypeCategory = { data: [], lastUpdateTime: "" };
        state.netCashByTypeCategory = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })

      // getReportalReport
      .addCase(getReportalReport.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getReportalReport.fulfilled, (state, { payload }) => {
        state.loading = "succeeded";
        state.reportalData = payload;
      })

      .addCase(getReportalReport.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })

      // get reportal finance data

      // get Market info
      .addCase(getMarketCompanyInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getMarketCompanyInfo.fulfilled, (state, { payload }) => {
        state.loading = "succeeded";
        state.market = payload;
      })
      .addCase(getMarketCompanyInfo.rejected, (state) => {
        state.loading = "failed";
      })
      // get Market company info
      .addCase(getMarketAllCompanyInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getMarketAllCompanyInfo.fulfilled, (state, { payload }) => {
        state.marketWorkOutcomeCompanyInfo = payload;
        state.loading = "succeeded";
      })
      .addCase(getMarketAllCompanyInfo.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export const {
  resetDashboardStore,
  updateRevenueFilter,
  updateExpensesFilter,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
