import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BarChartTitleContainer,
  GroupBarChartContainer,
  GroupDetailChartWrapper,
  Header,
  LastUpdated,
  SkeletonWrapper,
  Title,
  TitleWrapper,
  TreeChartContainer,
  UpdateDateSkeleton,
  Wrapper,
} from "./Expenses.style";

import BigCardSkeleton from "../../../../components/shared/dashboard/skeletons/bigCardSkeleton/BigCardSkeleton";
import {
  dashboardDateFormat,
  downloadFile,
  generateGridChartModes,
} from "../../../../helpers/helper";
import Filters from "../../../../components/shared/dashboard/filters/Filters";
import CardGroupsSkeleton from "../../../../components/shared/dashboard/skeletons/cardGroupsSkeleton/CardGroupsSkeleton";
import DashboardChartCard from "../../../../components/shared/card/Card";

import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  getExpensesGroups,
  getExpensesGroupsExcel,
} from "../../../../store/dashboard/dashboardAction";
import {
  getCompanyBranch,
  getCompanyBusinessLine,
} from "../../../../store/company/companyAction";
import {
  CardTypes,
  ChartMode,
  ChartType,
  PeriodType,
  RangeType,
} from "../../../../enums/enum";

import { FinancialGroupsRequest } from "../../../../store/dashboard/dashboard.types";
import GroupBarChart from "../../../../components/desktop/charts/revenueExpenses/groupBar/GroupBarChart";
import TreeChart from "../../../../components/desktop/charts/revenueExpenses/treeChart/TreeChart";
import BarChartLabels from "../../../../components/shared/dashboard/barChartLabels/BarChartLabels";

import TableChart from "../../../../components/desktop/charts/revenueExpenses/tableChart/TableChart";
import ChartTypeSwitcher from "../../../../components/shared/dashboard/chartTypeSwitcher/ChartTypeSwitcher";
import GridChart from "../../../../components/desktop/charts/revenueExpenses/gridChart/GridChart";
import { formatNumber } from "../../../../helpers/dashboardHelper";

import { useNavigate } from "react-router-dom";
import { ActionType } from "../../../../store/type";
import { isMobile } from "react-device-detect";
import EmptyDashboard from "../../../../components/shared/emptyDashboard/EmptyDashboard";
import {
  initialRevenueExpensesFilterData,
  updateExpensesFilter,
} from "../../../../store/dashboard/dashboardSlice";
import { sumRevenueExpenses } from "../../../../helpers/profitLossHelper";

const Expenses = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isShowClearAll, setIsShowClearAll] = useState(false);

  const [cardViewMode, setCardViewMode] = useState<ChartMode>(
    ChartMode.Stacked
  );
  const [viewMode, setViewMode] = useState<ChartMode>(ChartMode.Grid);
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(
    ChartType.GROUP
  );

  const companyBusinessLine = useAppSelector(
    (state) => state.company.companyBusinessLine
  ).map((item) => ({ id: item.id.toString(), name: item.name }));

  const companyBranches = useAppSelector(
    (state) => state.company.companyBranch
  ).map((item) => ({ id: item.id.toString(), name: item.name }));

  const expensesFilter = useAppSelector(
    (state) => state.dashboard.expensesFilter
  );

  const expenses = useAppSelector(
    (state) => state.dashboard.expensesGroupChartData
  );

  const expensesGroupsOption = useAppSelector(
    (state) => state.dashboard.expensesGroupsOption
  );

  const loading = useAppSelector((state) => state.dashboard.expensesLoading);

  const hasNoData = useMemo(() => {
    return expenses.data.every((item) => item.sumAmount === 0);
  }, [expenses.data]);

  const totalSum = useMemo(() => {
    return formatNumber(sumRevenueExpenses(expenses.data));
  }, [expenses.data]);

  useEffect(() => {
    const promises = [
      dispatch(getCompanyBusinessLine()),
      dispatch(getCompanyBranch()),
    ];
    Promise.all(promises).finally(() => {});
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getExpensesGroups({
        periodType: expensesFilter.period,
        fromDate: expensesFilter.dateRange.range[0],
        toDate: expensesFilter.dateRange.range[1],
        chartType: expensesFilter.chartType,
        companyBranchIds: expensesFilter.branches,
        companyBusinessLineIds: expensesFilter.businessLines,
        accountMappingGroupIds: expensesFilter.groups,
      })
    );
  }, [dispatch, expensesFilter]);

  const handleFilter = ({
    businessLines,
    branches,
    groups,
    periodType,
    chartType,
    dateRange,
    dateType,
  }: {
    businessLines?: Array<string | number>;
    branches?: Array<string | number>;
    groups?: Array<string | number>;
    periodType?: PeriodType;
    chartType?: ChartType;
    dateRange?: Array<string>;
    dateType?: RangeType;
  }) => {
    let data: FinancialGroupsRequest = {
      periodType: periodType ? periodType : expensesFilter.period,
      fromDate: dateRange?.length
        ? dateRange[0]
        : expensesFilter.dateRange.range[0],
      toDate: dateRange?.length
        ? dateRange[1]
        : expensesFilter.dateRange.range[1],
      chartType: chartType ? chartType : selectedChartType,
    };
    if (businessLines?.length || expensesFilter.businessLines.length) {
      data = {
        ...data,
        companyBusinessLineIds: businessLines?.length
          ? businessLines
          : expensesFilter.businessLines,
      };
    }
    if (branches?.length || expensesFilter.branches.length) {
      data = {
        ...data,
        companyBranchIds: branches?.length ? branches : expensesFilter.branches,
      };
    }
    if (groups?.length || expensesFilter.groups?.length) {
      data = {
        ...data,
        accountMappingGroupIds: groups?.length ? groups : expensesFilter.groups,
      };
    }

    dispatch(
      updateExpensesFilter({
        dateRange: {
          type:
            dateType !== undefined ? dateType : expensesFilter.dateRange.type,
          range: [data.fromDate, data.toDate],
        },
        period: data.periodType || expensesFilter.period,
        groups: data.accountMappingGroupIds || expensesFilter.groups,
        chartType: data.chartType || expensesFilter.chartType,
        branches: data.companyBranchIds || expensesFilter.branches,
        businessLines:
          data.companyBusinessLineIds || expensesFilter.businessLines,
      })
    );
  };

  const handleClearAll = () => {
    dispatch(updateExpensesFilter(initialRevenueExpensesFilterData));
  };

  const handleNavigationGroupDetail = (groupId: number | string) => {
    navigate(groupId.toString());
  };

  const handleDownload = () => {
    const data: FinancialGroupsRequest = {
      periodType: expensesFilter.period,
      fromDate: expensesFilter.dateRange.range[0],
      toDate: expensesFilter.dateRange.range[1],
      chartType: selectedChartType,
      companyBranchIds: expensesFilter.branches,
      companyBusinessLineIds: expensesFilter.businessLines,
      accountMappingGroupIds: expensesFilter.groups,
    };

    dispatch(getExpensesGroupsExcel(data))
      .then((action: ActionType) => {
        if (action.error) {
          console.error("Download failed:", action.error);
        } else if (action?.payload) {
          downloadFile(action?.payload);
        }
      })
      .catch((error) => {
        console.error("Unexpected error during download:", error);
      });
  };

  if (isMobile) return <div>under construction</div>;

  return (
    <Wrapper>
      <Header>
        <TitleWrapper>
          <Title>{t("Expenses.Title")}</Title>
          {expenses.lastUpdateTime ? (
            <LastUpdated>
              {t("Expenses.DateUpdatedTitle")}:{" "}
              {dashboardDateFormat(expenses.lastUpdateTime)}
            </LastUpdated>
          ) : (
            <UpdateDateSkeleton />
          )}
        </TitleWrapper>

        <Filters
          // isLoading={loading.groups === "pending"}
          isShowClearAll={isShowClearAll}
          groupOptions={expensesGroupsOption}
          selectedDateRange={expensesFilter.dateRange}
          selectedBranches={expensesFilter.branches}
          selectedGroups={expensesFilter.groups}
          selectedBusinessLine={expensesFilter.businessLines}
          branchOptions={companyBranches}
          lineOptions={companyBusinessLine}
          handleDateRange={(dateData) => {
            setIsShowClearAll(true);
            handleFilter({
              dateRange: dateData.range,
              dateType: dateData.type,
            });
          }}
          handleBusinessLines={(businessLines) => {
            setIsShowClearAll(true);
            handleFilter({ businessLines });
          }}
          handleBranches={(branches) => {
            setIsShowClearAll(true);
            handleFilter({ branches });
          }}
          handleGroups={(groups) => {
            setIsShowClearAll(true);
            handleFilter({ groups });
          }}
          handleClearAll={() => {
            setIsShowClearAll(false);

            handleClearAll();
          }}
          handleDownload={handleDownload}
        />
      </Header>
      {loading.groups === "pending" ? (
        <SkeletonWrapper>
          <BigCardSkeleton />
          <CardGroupsSkeleton />
        </SkeletonWrapper>
      ) : (
        <>
          {hasNoData ? (
            <EmptyDashboard />
          ) : (
            <>
              <DashboardChartCard
                selectedPeriodType={expensesFilter.period}
                selectedChartType={selectedChartType}
                selectedViewType={cardViewMode}
                type={CardTypes.LARGE}
                title={t("Expenses.DashboardCardTitle")}
                value={totalSum}
                hasBranch={Boolean(companyBranches.length)}
                hasLine={Boolean(companyBusinessLine.length)}
                handlePeriodType={(periodType) => {
                  handleFilter({ periodType });
                }}
                handleChartType={(chartType) => {
                  setSelectedChartType(chartType);
                  handleFilter({ chartType });
                }}
                handleViewMode={setCardViewMode}
              >
                {cardViewMode === ChartMode.Stacked && (
                  <>
                    <BarChartLabels data={expenses.data} />
                    <GroupBarChartContainer>
                      <GroupBarChart
                        data={expenses.data}
                        periodType={expensesFilter.period}
                        stackId={"a"}
                      />
                    </GroupBarChartContainer>
                  </>
                )}
                {cardViewMode === ChartMode.Tree && (
                  <TreeChartContainer>
                    <TreeChart data={expenses.data} />
                  </TreeChartContainer>
                )}
              </DashboardChartCard>
              <GroupDetailChartWrapper>
                <BarChartTitleContainer>
                  <Title>{t("Expenses.GroupChartTitle")}</Title>
                  <ChartTypeSwitcher
                    viewOptions={generateGridChartModes()}
                    selectedViewMode={viewMode}
                    handleViewMode={setViewMode}
                  />
                </BarChartTitleContainer>
                {viewMode === ChartMode.Grid && (
                  <GridChart
                    handleViewDetail={
                      selectedChartType === ChartType.GROUP
                        ? handleNavigationGroupDetail
                        : undefined
                    }
                    periodType={expensesFilter.period}
                    data={expenses.data}
                  />
                )}
                {viewMode === ChartMode.Table && (
                  <TableChart
                    handleClick={
                      selectedChartType === ChartType.GROUP
                        ? handleNavigationGroupDetail
                        : undefined
                    }
                    periodType={expensesFilter.period}
                    data={expenses.data}
                  />
                )}
              </GroupDetailChartWrapper>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Expenses;
