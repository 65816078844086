
import {
  CashFlowRecord,
  FinancialInDetailRecord,
  FinancialRecord,
  NetIncomeResponseRecord,
} from "../store/dashboard/dashboard.types";



export const getUniquePeriods = (
  data: Array<FinancialRecord | NetIncomeResponseRecord | CashFlowRecord>
): string[] => {
  return Array.from(new Set(data.map((item) => item.period)));
};


export type GridDataItem = {
  id: number | string;
  title: string;
  label?: string;
  percentage?: boolean;
  data: { [key: string]: number | string; period: string }[];
};



// old version

export const formatNumber = (sum: number) =>
  sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getMonthlyPeriods = (
  fromDate: string,
  toDate: string
): string[] => {
  const start = new Date(fromDate);
  const end = new Date(toDate);
  const result: string[] = [];

  const current = new Date(start.getFullYear(), start.getMonth(), 1);

  while (current <= end) {
    const month = current.getMonth() + 1;
    const formattedMonth = month < 10 ? `0${month}` : month.toString();
    result.push(`${current.getFullYear()}-${formattedMonth}`);
    current.setMonth(current.getMonth() + 1);
  }

  return result;
};

export const processAccountData = (
  data: Array<FinancialInDetailRecord>
): Record<string, number> => {
  // Sum totals for each accountId
  const totals: Record<string, number> = {};
  data.forEach((entry) => {
    if (!totals[entry.accountId]) {
      totals[entry.accountId] = 0;
    }
    totals[entry.accountId] += entry.total;
  });

  // Rank accountIds based on their totals
  const sortedAccountIds = Object.entries(totals)
    .sort(([, totalA], [, totalB]) => totalB - totalA)
    .map(([accountId]) => accountId);

  // Assign ranks
  const ranking: Record<string, number> = {};
  sortedAccountIds.forEach((accountId, index) => {
    ranking[accountId] = index + 1;
  });

  return ranking;
};
