import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createCompany,
  createCompanyBranch,
  createCompanyBusinessLine,
  deleteCompanyBranch,
  deleteCompanyBusinessLine,
  editCompanyBranch,
  editCompanyBusinessLine,
  getCompanyBranch,
  getCompanyBusinessLine,
  getCompanyInfo,
} from "./companyAction";

import {
  Company,
  CompanyBranchType,
  CompanyBusinessLineType,
} from "./company.types";
import { LoaderType } from "../type";

export type ReportState = {
  company: Company | null;
  hasError: boolean;
  loading: LoaderType;
  companyBranch: Array<CompanyBranchType>;
  companyBusinessLine: Array<CompanyBusinessLineType>;
};

const initialState = (): ReportState => ({
  company: null,
  hasError: false,
  loading: "idle",
  companyBranch: [],
  companyBusinessLine: [],
});

export const companySlice = createSlice({
  name: "companySlice",
  initialState: initialState(),
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ReportState>) => {
    builder
      .addCase(getCompanyInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        getCompanyInfo.fulfilled,
        (state, { payload }: PayloadAction<Company>) => {
          state.company = payload;
          state.loading = "succeeded";
        }
      )
      .addCase(getCompanyInfo.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })
      // getCompanyBranch
      .addCase(getCompanyBranch.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getCompanyBranch.fulfilled, (state, { payload }) => {
        const sortedPayload = payload.sort(
          (a: any, b: any) => a.ordering - b.ordering
        );
        state.companyBranch = sortedPayload;
        state.loading = "succeeded";
      })
      .addCase(getCompanyBranch.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })
      // createCompanyBranch
      .addCase(createCompanyBranch.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(createCompanyBranch.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(createCompanyBranch.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })
      // editCompanyBranch
      .addCase(editCompanyBranch.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(editCompanyBranch.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(editCompanyBranch.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })
      // deleteCompanyBranch
      .addCase(deleteCompanyBranch.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteCompanyBranch.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(deleteCompanyBranch.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      });
    // companyBusinessLine
    builder
      .addCase(getCompanyBusinessLine.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getCompanyBusinessLine.fulfilled, (state, { payload }) => {
        const sortedPayload = payload.sort(
          (a: any, b: any) => a.ordering - b.ordering
        );
        state.companyBusinessLine = sortedPayload;
        state.loading = "succeeded";
      })
      .addCase(getCompanyBusinessLine.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })
      // createCompanyBusinessLine
      .addCase(createCompanyBusinessLine.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(createCompanyBusinessLine.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(createCompanyBusinessLine.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })
      // editCompanyBusinessLine
      .addCase(editCompanyBusinessLine.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(editCompanyBusinessLine.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(editCompanyBusinessLine.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })
      // deleteCompanyBusinessLine
      .addCase(deleteCompanyBusinessLine.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteCompanyBusinessLine.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(deleteCompanyBusinessLine.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })
      .addCase(createCompany.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(createCompany.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(createCompany.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      });
  },
});

export default companySlice.reducer;
