import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  getList,
  GenerateUploadUrl,
  getExpenseByCounterPartyAndPeriod,
  getEmployeeRemunerationAndCostItemsReport,
  getEmployeeByStaffAndPeriod,
  getRevenuePeriod,
  getRevenueStream,
  getWorkingOutComeDetailedReport,
  getFinanceStandingDetailedReport,
  getRevenueServices,
  updateRevenueServiceReload,
} from "./reportAction";
import {
  EmployeeRemuneration,
  getListResponse,
  ReportItem,
  ReportRevenuePeriod,
  ReportRevenueStream,
  WorkingOutComeDetailed,
  FinanceDetailed,
  ReportsItemType,
  RevenueServices,
} from "./report.types";
import { LoaderType } from "../type";
import { getMonthlyPeriods } from "../../helpers/dashboardHelper";

export type ReportState = {
  reportList: {
    items: Array<ReportItem>;
    count: number;
    page: number;
    pageSize: number;
  };
  revenue: {
    period: { labels: Array<string>; data: Array<ReportRevenuePeriod> };
    stream: { labels: Array<string>; data: Array<ReportRevenueStream> };
  };
  reportal: {
    workingOutComeDetailed: Array<WorkingOutComeDetailed>;
    financeDetailed: Array<FinanceDetailed>;
  };
  expensesCounterPartyAndPeriod: {
    labels: Array<string>;
    data: Array<ReportsItemType>;
  };
  employeeRemuneration: EmployeeRemuneration;
  employeeByStaffAndPeriod: {
    labels: Array<string>;
    data: Array<ReportRevenuePeriod>;
  };
  revenueServices: RevenueServices;
  hasError: boolean;
  loading: LoaderType;
};

const initialState = (): ReportState => ({
  reportList: { count: 0, items: [], page: 0, pageSize: 20 },
  expensesCounterPartyAndPeriod: { labels: [], data: [] },
  revenue: {
    period: { labels: [], data: [] },
    stream: { labels: [], data: [] },
  },
  reportal: {
    workingOutComeDetailed: [],
    financeDetailed: [],
  },
  employeeRemuneration: {
    count: 0,
    headers: [],
    items: [],
    page: 0,
    pageSize: 20,
  },
  employeeByStaffAndPeriod: { labels: [], data: [] },
  revenueServices: {
    count: 0,
    items: [],
    page: 1,
    pageSize: 10,
  },
  hasError: false,
  loading: "idle",
});

export const reportSlice = createSlice({
  name: "reportSlice",
  initialState: initialState(),
  reducers: {
    resetReportStore: () => initialState(),
  },
  extraReducers: (builder: ActionReducerMapBuilder<ReportState>) => {
    // get List
    builder
      .addCase(getList.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        getList.fulfilled,
        (state, { payload }: PayloadAction<getListResponse>) => {
          state.reportList = payload;
          state.loading = "succeeded";
        }
      )
      .addCase(getList.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      });

    // upload file
    builder
      .addCase(GenerateUploadUrl.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(GenerateUploadUrl.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(GenerateUploadUrl.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      });
    builder
      .addCase(getExpenseByCounterPartyAndPeriod.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        getExpenseByCounterPartyAndPeriod.fulfilled,
        (state, { payload }) => {
          state.loading = "succeeded";
          const periods = getMonthlyPeriods(payload.fromDate, payload.toDate);
          const headers = [
            "Report.AccountId",
            "Report.AccountName",
            ...periods,
            "Report.Total",
          ];
          const data: Array<ReportsItemType> = payload.data.items;

          state.expensesCounterPartyAndPeriod.labels = headers;
          state.expensesCounterPartyAndPeriod.data = data.map((item) => ({
            ...item,
            periodTotals: periods.map((period) => {
              const matchingPeriod = item.periodTotals.find((periodElement) =>
                periodElement.period.includes(period)
              );

              if (matchingPeriod) {
                return matchingPeriod;
              } else {
                return { period, total: 0 };
              }
            }),
          }));
        }
      )
      .addCase(getExpenseByCounterPartyAndPeriod.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      });

    // getEmployeeRemunerationAndCostItemsReport
    builder
      .addCase(getEmployeeRemunerationAndCostItemsReport.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        getEmployeeRemunerationAndCostItemsReport.fulfilled,
        (state, { payload }) => {
          state.loading = "succeeded";
          state.employeeRemuneration = payload;
        }
      )
      .addCase(getEmployeeRemunerationAndCostItemsReport.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      });
    // getEmployeeByStaffAndPeriod
    builder
      .addCase(getEmployeeByStaffAndPeriod.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getEmployeeByStaffAndPeriod.fulfilled, (state, { payload }) => {
        state.loading = "succeeded";

        const periods = getMonthlyPeriods(payload.fromDate, payload.toDate);
        const headers = [
          "Report.AccountId",
          "Report.AccountName",
          ...periods,
          "Report.Total",
        ];
        const data: Array<ReportsItemType> = payload.data.items;

        state.employeeByStaffAndPeriod.labels = headers;
        state.employeeByStaffAndPeriod.data = data.map((item) => ({
          ...item,
          periodTotals: periods.map((period) => {
            const matchingPeriod = item.periodTotals.find((periodElement) =>
              periodElement.period.includes(period)
            );

            if (matchingPeriod) {
              return matchingPeriod;
            } else {
              return { period, total: 0 };
            }
          }),
        }));
      })
      .addCase(getEmployeeByStaffAndPeriod.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })
      // getRevenuePeriod
      .addCase(getRevenuePeriod.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getRevenuePeriod.fulfilled, (state, { payload }) => {
        state.loading = "succeeded";

        const data: Array<ReportRevenuePeriod> = payload.data.items;

        const periods = getMonthlyPeriods(payload.fromDate, payload.toDate);

        const headers = [
          "Report.AccountId",
          "Report.AccountName",
          ...periods,
          "Report.Total",
        ];

        state.revenue.period.labels = headers;
        state.revenue.period.data = data.map((item) => ({
          ...item,
          periodTotals: periods.map((period) => {
            const matchingPeriod = item.periodTotals.find((periodElement) =>
              periodElement.period.includes(period)
            );

            if (matchingPeriod) {
              return matchingPeriod;
            } else {
              return { period, total: 0 };
            }
          }),
        }));
      })
      .addCase(getRevenuePeriod.rejected, (state) => {
        state.loading = "failed";
      }) // getRevenueStream
      .addCase(getRevenueStream.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getRevenueStream.fulfilled, (state, { payload }) => {
        state.loading = "succeeded";
        state.revenue.stream.labels = payload.data.headers;
        state.revenue.stream.data = payload?.data?.items;
      })
      .addCase(getRevenueStream.rejected, (state) => {
        state.loading = "failed";
      })
      // get Working Out Come Detailed
      .addCase(getWorkingOutComeDetailedReport.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        getWorkingOutComeDetailedReport.fulfilled,
        (state, { payload }) => {
          state.loading = "succeeded";
          state.reportal.workingOutComeDetailed = payload;
        }
      )
      .addCase(getWorkingOutComeDetailedReport.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(
        getFinanceStandingDetailedReport.fulfilled,
        (state, { payload }) => {
          state.reportal.financeDetailed = payload;
          state.loading = "succeeded";
        }
      )
      .addCase(getFinanceStandingDetailedReport.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(getFinanceStandingDetailedReport.pending, (state) => {
        state.loading = "pending";
      })
      //getRevenueServices
      .addCase(getRevenueServices.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getRevenueServices.fulfilled, (state, { payload }) => {
        state.loading = "succeeded";
        state.revenueServices = payload;
      })
      .addCase(getRevenueServices.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })
      .addCase(updateRevenueServiceReload.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateRevenueServiceReload.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(updateRevenueServiceReload.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      });
  },
});

export default reportSlice.reducer;

export const { resetReportStore } = reportSlice.actions;
